<template>
  <!--Modal demande d'approbation -->
  <div class="modalApproval">
    <SfModal
      :cross="true"
      :visible="isApprovalModalOpen"
      @close="toggleApprovalModal"
    >
      <div>
        <p class="title">Votre demande d'approbation</p>
      </div>
      <div class="bloc-approval">
        <div class="bloc-approval--step">
          <div class="outline">
            <p>1</p>
          </div>
          <p class="text">Vous effectuez une demande d'approbation à <span>{{ lastnameDirigeant }} {{ firstnameDirigeant }}</span> en cliquant sur le
            bouton <span>Soumettre à la validation dans votre panier.</span></p>
        </div>
        <div class="bloc-approval--step">
          <div class="outline">
            <p>2</p>
          </div>
          <p class="text">Vous recevez <span>par email le récapitulatif</span> de votre demande d'approbation.</p>
        </div>
        <div class="bloc-approval--step">
          <div class="outline">
            <p>3</p>
          </div>
          <p class="text">Vous êtes avertis <span>par email de la validation</span> ou <span>du refus</span> de votre demande.</p>
        </div>
        <div class="bloc-approval--step">
          <div class="outline">
            <p>4</p>
          </div>
          <p class="text">Votre demande est acceptée. <span>Vous pouvez alors finaliser votre commande</span> en ligne depuis votre panier.​</p>
        </div>
      </div>
    </SfModal>
  </div>
</template>
<script>
import { ref } from '@nuxtjs/composition-api';
import { SfButton, SfModal } from '@storefront-ui/vue';
import SvgImage from "~/components/General/SvgImage";
import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';

export default {
  name: 'CartApprovalModal',
  components: {
    SfModal,
    SfButton,
    SvgImage
  },
  setup() {
    const { isApprovalModalOpen, toggleApprovalModal } = useUiState();
    const { user, load }                               = useUser();
    const firstnameDirigeant                           = ref('');
    const lastnameDirigeant                            = ref('');

    if (user.value === null) {
      load();
    } else {
      firstnameDirigeant.value = user.value.dirigeant?.firstname;
      lastnameDirigeant.value  = user.value.dirigeant?.lastname;
    }

    return {
      isApprovalModalOpen,
      toggleApprovalModal,
      firstnameDirigeant,
      lastnameDirigeant
    };
  },
};
</script>
